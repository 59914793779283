exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-posts-tsx": () => import("./../../../src/pages/posts.tsx" /* webpackChunkName: "component---src-pages-posts-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-templates-ghost-author-tsx": () => import("./../../../src/templates/GhostAuthor.tsx" /* webpackChunkName: "component---src-templates-ghost-author-tsx" */),
  "component---src-templates-ghost-post-tsx": () => import("./../../../src/templates/GhostPost.tsx" /* webpackChunkName: "component---src-templates-ghost-post-tsx" */),
  "component---src-templates-ghost-tag-tsx": () => import("./../../../src/templates/GhostTag.tsx" /* webpackChunkName: "component---src-templates-ghost-tag-tsx" */)
}

